import React from 'react';
import TagManager from 'helpers/googleTagManager/googleTag';
import { useFormat } from 'helpers/hooks/useFormat';
import { trackCartClear } from 'scripts/collect';
import { useCart } from 'frontastic';
import { cartItems } from 'frontastic/actions/cart';
import styles from './remove-from-cart-modal.module.scss';

interface Props {
  lineItemId: string;
  onRemoveItem: (lineItemId: string) => void;
  onClose: (showConfirmationModal: boolean) => void;
}

// TODO: refactor to use common modal and backdrop components (modal component might need to be created)
const RemoveFromCartPopUp: React.FC<Props> = ({ lineItemId, onRemoveItem, onClose }) => {
  const cart: any = useCart();
  const { formatMessage } = useFormat({ name: 'common' });
  const tagManager = new TagManager();

  const closeConfirmationModal = (event) => {
    onClose(false);
    if (event.target.value === 'Yes') {
      try {
        tagManager.removeFromBag(cart.data.lineItems.find((lineItem) => lineItem.lineItemId === lineItemId));
      } catch (e) {
        console.error(e);
      }

      onRemoveItem(lineItemId);
      if (cart?.data?.lineItems?.length <= 1) {
        trackCartClear();
      }
    }
  };

  return (
    <div className={styles.wrapper} data-testid={'remove_from_cart_popup'}>
      <div className={styles.container}>
        <h5 data-testid="remove_from_cart_popup_description">
          {formatMessage({
            id: 'remove.from.cart.message',
            defaultMessage: 'Are you sure you want to remove this item from your cart?',
          })}
        </h5>

        <div className={styles.buttons}>
          <button value="No" data-testid={'remove_from_cart_popup_secondary_button'} onClick={closeConfirmationModal}>
            {formatMessage({ id: 'no', defaultMessage: 'No' })}
          </button>
          <button value="Yes" data-testid={'remove_from_cart_popup_primary_button'} onClick={closeConfirmationModal}>
            {formatMessage({ id: 'yes', defaultMessage: 'Yes' })}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RemoveFromCartPopUp;
