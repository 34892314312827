import { Cart } from '@Types/cart/Cart';
import { LineItem } from '@Types/cart/LineItem';
import { useFormat } from 'helpers/hooks/useFormat';
import Item, { ItemStyle } from './item';
import styles from './itemList.module.scss';

interface Props {
  readonly cart: Cart;
  readonly itemStyle: ItemStyle;
  readonly editItemQuantity?: (lineItem: LineItem, newQuantity: number) => void;
  readonly goToProductPage?: (_url: string) => void;
  readonly removeItem?: (lineItemId: string) => void;
  readonly isEditable?: boolean;
}

const ItemList = ({ cart, editItemQuantity, goToProductPage, removeItem, itemStyle, isEditable = true }: Props) => {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

  return (
    <section aria-labelledby="cart-heading">
      <h2 id="cart-heading" className="sr-only">
        {formatCartMessage({ id: 'cart.shopping.items', defaultMessage: 'Items in your shopping cart' })}
      </h2>

      <ul className="grid gap-[16px]" role="list" data-testid="cartpage_lineItems">
        {cart?.lineItems?.map((lineItem) => (
          <li key={lineItem.lineItemId} className={styles.listItem} data-testid="cart-item">
            <Item
              lineItem={lineItem}
              editItemQuantity={editItemQuantity}
              goToProductPage={goToProductPage}
              removeItem={removeItem}
              style={itemStyle}
              isEditable={isEditable}
            />
          </li>
        ))}
      </ul>
    </section>
  );
};

export default ItemList;
