interface TransformationTemplate {
  name: string;
  useWidth: boolean;
}

const templatesMap = new Map<string, TransformationTemplate>([
  ['General', { name: 'rebuild-general', useWidth: true }],
  ['Product display image -zoomed', { name: 'rebuild-pdp-zoomed', useWidth: false }],
  ['Product colour swatch image', { name: 'rebuild-swatch', useWidth: false }],
  ['Hero banner', { name: 'rebuild-hero-banner', useWidth: true }],
  ['PLP', { name: 'rebuild-plp', useWidth: true }],
  ['PDP', { name: 'rebuild-pdp', useWidth: true }],
]);

export function amplienceImageLoader(key: string, src: string, width: number) {
  const transformationTemplate = templatesMap.get(key);

  if (!transformationTemplate) return `${src}?&fmt=auto`;
  if (!transformationTemplate.useWidth) {
    return `${src}?$${transformationTemplate.name}$&fmt=auto`;
  }
  return `${src}?w=${width}&$${transformationTemplate.name}$&fmt=auto`;
}
