import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { LineItem } from '@Types/cart/LineItem';
import classNames from 'classnames';
import { useFormat } from 'helpers/hooks/useFormat';
import { amplienceImageLoader } from 'helpers/imageLoaders/amplienceImageLoader';
import { getProductImages } from '../../../frontastic/actions/amplience';
import IconClose from '../../icons/close';
import Price from '../price';
import styles from './item.module.scss';
import RemoveFromCartPopUp from './remove-from-cart-modal';

interface ItemProps {
  lineItem: LineItem;
  goToProductPage?: (_url: string) => void;
  editItemQuantity?: (lineItem: LineItem, newQuantity: number) => void;
  removeItem?: (lineItemId: string) => void;
  style: ItemStyle;
  isEditable?: boolean;
}

export type ItemStyle = 'checkout' | 'cart' | 'miniCart';

const Item: React.FC<ItemProps> = ({
  lineItem,
  goToProductPage,
  editItemQuantity,
  removeItem,
  style,
  isEditable = true,
}) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const [image, setImage] = useState({ src: '', alt: '' });
  const amountLimit = lineItem.variant.availableQuantity;
  const outOfStock = amountLimit === 0;
  const limitedStock = amountLimit < lineItem.count;
  const altImageSrc = 'https://cdn.media.amplience.net/i/lisroyan/TP21FITG002';
  const variant = {
    size: lineItem.variant.attributes['TP_SIZE'],
    sleeve: lineItem.variant.attributes['TP_SLEEVE_LENGTH'],
    cuff: lineItem.variant.attributes['TP_CUFF'],
    quantity: lineItem.count,
  };
  const [showRemoveFromCartModal, setShowRemoveFromCartModal] = useState(false);

  useEffect(() => {
    async function fetchImage() {
      const result = await getProductImages(lineItem.variant.attributes?.TP_PRODUCT_ID);

      if (result.images && !image.src) {
        setImage(result.images[0]);
      }
    }

    fetchImage();
  }, []);

  useEffect(() => {
    if (showRemoveFromCartModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showRemoveFromCartModal]);

  const removeItemFromBasket = () => {
    if (!window.location.pathname.includes('/checkout')) {
      setShowRemoveFromCartModal(true);
    } else {
      removeItem(lineItem.lineItemId);
    }
  };

  return (
    <div className={styles.container} data-testid={`${lineItem.productId}_product`}>
      <div
        className={classNames(styles.productImage, {
          [styles.productImage__cart]: style === 'cart',
          [styles.productImage__miniCart]: style === 'miniCart',
        })}
      >
        <Image
          layout="fill"
          src={image.src || altImageSrc}
          alt={image.alt}
          loader={({ src, width }) => amplienceImageLoader('General', src, width)}
          sizes="20vw"
          className="h-full w-full object-cover"
          onClick={() => goToProductPage(lineItem._url)}
        />
      </div>

      <div className={styles.productInfo}>
        <div
          className={classNames(styles.productNameAndPrice, {
            [styles.productNameAndPrice__cart]: style === 'cart',
            [styles.productNameAndPrice__miniCart]: style === 'miniCart',
          })}
        >
          <h2
            className={classNames(styles.productName, { [styles.productName__clickable]: !!goToProductPage })}
            onClick={goToProductPage && (() => goToProductPage(lineItem._url))}
          >
            {lineItem.name}
          </h2>

          <div>
            {lineItem.discountedPrice && (
              <Price
                price={{
                  ...lineItem.discountedPrice,
                  centAmount: variant?.quantity * lineItem?.discountedPrice?.centAmount,
                }}
                minimumFractionDigits={0}
                className={classNames(styles.productPrice, { [styles.productPrice__cart]: style !== 'checkout' })}
              />
            )}
            <Price
              price={{
                ...lineItem.price,
                centAmount: variant?.quantity * lineItem?.price?.centAmount,
              }}
              minimumFractionDigits={0}
              className={classNames(styles.productPrice, {
                [styles.productPrice__discounted]: !!lineItem?.discountedPrice,
                [styles.productPrice__cart]: style !== 'checkout',
              })}
            />
          </div>
        </div>

        <div className={classNames(styles.variantInfo, { [styles.variantInfo__cart]: style !== 'checkout' })}>
          {variant.size && <p>{variant.size}</p>}
          {variant.sleeve && <p>{variant.sleeve}</p>}
          {variant.cuff && <p>{variant.cuff}</p>}
          {!isEditable && variant.quantity && <p>Quantity: {variant.quantity}</p>}
        </div>

        {isEditable && (
          <>
            <div className={styles.quantitySelectorContainer}>
              {!outOfStock && (
                <div className={styles.quantitySelector}>
                  <button
                    type="button"
                    aria-label="Reduce"
                    className={classNames([
                      {
                        'cursor-pointer': lineItem.count > 1,
                        'opacity-0': lineItem.count === 1,
                      },
                    ])}
                    disabled={lineItem.count <= 1}
                    onClick={() => {
                      editItemQuantity(lineItem, lineItem.count - 1);
                    }}
                  >
                    –
                  </button>
                  <span>{lineItem.count}</span>
                  <button
                    type="button"
                    aria-label="Increment"
                    className={classNames([
                      {
                        'cursor-pointer': lineItem.count < amountLimit,
                        'opacity-0': lineItem.count >= amountLimit,
                      },
                    ])}
                    disabled={lineItem.count >= amountLimit}
                    onClick={() => {
                      editItemQuantity(lineItem, lineItem.count + 1);
                    }}
                    data-testid={`${lineItem.productId}_increase_button`}
                  >
                    +
                  </button>
                </div>
              )}
              {outOfStock && style === 'miniCart' && (
                <div className="shrink text-sm text-red-500" data-testid="warning_label">
                  {formatCartMessage({
                    id: 'outOfStock',
                  })}
                </div>
              )}
              {style === 'miniCart' && (
                <button
                  type="button"
                  aria-label={formatMessage({ id: 'remove', defaultMessage: 'Remove' })}
                  className={classNames([styles.btnRemove, 'flex-none'])}
                  onClick={removeItemFromBasket}
                >
                  <IconClose />
                </button>
              )}
            </div>

            <div className={styles.removeContainer}>
              {!outOfStock && (
                <div className={styles.priceContainer}>
                  Price for 1:&nbsp;
                  {lineItem.discountedPrice && (
                    <span>
                      <Price
                        price={lineItem.discountedPrice}
                        minimumFractionDigits={0}
                        className={styles.productPrice}
                      />
                    </span>
                  )}
                  <span>
                    <Price
                      price={lineItem.price}
                      minimumFractionDigits={0}
                      className={classNames(styles.productPrice, {
                        [styles.productPrice__discounted]: !!lineItem.discountedPrice,
                      })}
                    />
                  </span>
                </div>
              )}
              {outOfStock && style === 'cart' && (
                <div className="text-sm text-red-500" data-testid="warning_label">
                  {formatCartMessage({
                    id: 'outOfStock',
                  })}
                </div>
              )}
              {style === 'cart' && (
                <button type="button" onClick={removeItemFromBasket} className={styles.btnRemove}>
                  <IconClose />
                  {formatMessage({ id: 'remove', defaultMessage: 'Remove' })}
                </button>
              )}
            </div>
          </>
        )}
        {outOfStock && !isEditable && (
          <div className="text-sm text-red-500" data-testid="warning_label">
            {formatCartMessage({
              id: 'outOfStock',
            })}
          </div>
        )}
        {!outOfStock && limitedStock && (
          <div className="mt-6 text-sm text-red-500" data-testid="warning_label">
            {formatCartMessage({
              id: 'limitedStock',
              values: {
                count: amountLimit,
              },
            })}
          </div>
        )}
      </div>

      {showRemoveFromCartModal && isEditable && (
        <RemoveFromCartPopUp
          lineItemId={lineItem.lineItemId}
          onRemoveItem={removeItem}
          onClose={setShowRemoveFromCartModal}
        />
      )}
    </div>
  );
};

export default Item;
