import React from 'react';

type Props = {
  className?: string;
};
const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M5 0.5V11.5" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M0.5 7L5 11.5L9.5 7" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Icon;
